import dynamic from "next/dynamic";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import formatUrl from "utils/FormatUrl";
import PlacesAutocomplete from "../../../utils/PlacesAutocomplete/PlacesAutocomplete";
import style from "./style.module.css";

const LoginRegisterDialog = dynamic(
  () => import("components/LoginRegisterDialog"),
  {
    ssr: false,
    suspense: true,
  }
);

function index(props) {
  const userRedux = useSelector((store?: any) => store?.auth?.user);
  const [isAuthDialogVisible, setAuthDialogVisible] = useState(false);

  const [coordonnates, setcoordonnates] = useState({
    lat: "",
    lng: "",
    quartier: "",
    adresse: "",
    commune: "",
    province: "",
    region: "",
    niveau: "",
    zoom: "",
  });
  const dispatch = useDispatch();

  const postHandleSelect = (recherche) => {
    setcoordonnates((prevState) => ({
      ...prevState,
      lat: recherche.lat,
      lng: recherche.lng,
      quartier: "",
      adresse: recherche.adresse ? recherche.adresse : "",
      commune: recherche.commune ? recherche.commune : "",
      province: recherche.province ? recherche.province : "",
      region: recherche.region ? recherche.region : "",
      niveau: recherche.niveau,
      zoom: recherche.zoom,
    }));
    setcoordonnates((prevState) => ({ ...prevState, handle_recherche: true }));
    handleUrl(recherche);
  };

  const handleUrl = async (recherche) => {
    let new_params = new URLSearchParams(window.location.search);

    if (recherche) {
      new_params.set(
        "address",
        recherche.adresse
          ? recherche.adresse
          : coordonnates.adresse
          ? coordonnates.adresse
          : ""
      );
      new_params.set(
        "lat",
        recherche.lat ? recherche.lat : coordonnates.lat ? coordonnates.lat : ""
      );
      new_params.set(
        "lng",
        recherche.lng ? recherche.lng : coordonnates.lng ? coordonnates.lng : ""
      );
      new_params.set(
        "zoom",
        recherche.zoom
          ? recherche.zoom
          : coordonnates.zoom
          ? coordonnates.zoom
          : ""
      );
    }
    if (new_params) {
      if (props.buttonLink) {
        window.location.href = `/fr/${
          props.buttonLink
        }?${new_params.toString()}&&transaction_type=vente`;
      } else if (props.button == "J'estime") {
        window.location.href = `/fr/estimation/map?${new_params.toString()}`;
      } else if (props.button == "أقدر") {
        window.location.href = `/ar/estimation/map?${new_params.toString()}`;
      } else {
        if (userRedux) {
          window.location.href = `/${
            props.lang
          }/user/annonces/publier?${new_params.toString()}`;
        } else {
          setAuthDialogVisible(true);
        }
      }
    }
  };
  useEffect(() => {}, [userRedux]);

  const signInSuccess = () => {
    let new_params = new URLSearchParams("");
    new_params.set("adresse", coordonnates.adresse ? coordonnates.adresse : "");
    new_params.set("lat", coordonnates.lat ? coordonnates.lat : "");
    new_params.set("lng", coordonnates.lng ? coordonnates.lng : "");
    new_params.set("zoom", coordonnates.zoom ? coordonnates.zoom : "");
    new_params.set("version", "estimation");

    if (props.button == "J'estime") {
      window.location.href = window.location.href = `/${
        props.lang
      }/estimation/map?${new_params.toString()}`;
    } else {
      window.location.href = `/${
        props.lang
      }/user/annonces/publier?${new_params.toString()}`;
    }
  };
  return (
    <>
      <Suspense fallback={``}>
        <LoginRegisterDialog
          visible={isAuthDialogVisible}
          bodyScrollonClose={true}
          onClose={() => setAuthDialogVisible(false)}
          onFacebookSignInSuccess={() => signInSuccess()}
          onGoogleSignInSuccess={() => signInSuccess()}
          onRegisterSucccess={() => signInSuccess()}
          onSignInSuccess={() => signInSuccess()}
          onSwitchSignType={() => {
            return;
          }}
          Estimation={false}
        />
      </Suspense>
      <div className={style.searchInputButtonContainer}>
        <div className={style.searchCon}>
          <span className={style.subtitle}>{props.subtitle}</span>
          <div className={style.search}>
            <div className={style.searchInput}>
              <PlacesAutocomplete
                enableDefaultSearchList={false}
                placeholder={props.placeholder}
                postHandleSelect={postHandleSelect}
                page="landing-page"
                hide_icon={true}
                showSearchIcon={true}
                lang={props.lang}
              />
            </div>
            <div className={style.btnCon}>
              <a
                href={formatUrl(
                  props.buttonLink
                    ? props.buttonLink
                    : props.button == "J'estime"
                    ? `/estimation/map`
                    : "/user/annonces/publier"
                )}
                rel={
                  props.buttonLink?.includes("list") ||
                  props.buttonLink?.includes("map") ||
                  props.buttonLink?.includes("?") ||
                  props.buttonLink?.includes("conditions") ||
                  props.buttonLink?.includes("contact")
                    ? "noindex, nofollow, noreferrer"
                    : "dofollow"
                }
                onClick={(e) => {
                  void 0;
                  e.preventDefault();
                }}
              >
                {props.button ? (
                  <button
                    className={style.searchBtn}
                    onClick={() => handleUrl(coordonnates)}
                  >
                    {props.button}
                  </button>
                ) : (
                  <button className={style.searchBtn}>
                    <div className={style.btn__content}>
                      <i className="fas fa-circle-notch fa-spin"></i>
                    </div>
                  </button>
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default index;
