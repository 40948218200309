import { norm } from "components/v2/Listings/ListingsRouterUrlHelper";

const getUrlParams = (
  recherche,
  transaction_type,
  prixMax = 0,
  checked_type = []
) => {
  let params: any = {};
  params.version = "estimation";
  if (transaction_type) {
    params.transaction_type = transaction_type.toLowerCase();
  }

  if (recherche.address) {
    params.address = recherche.address;
    params.adresse = recherche.address;
  }
  if (recherche.adresse) {
    params.address = recherche.adresse;
    params.adresse = recherche.adresse;
  }
  if (recherche.zoom) {
    params.zoom = recherche.zoom;
  }
  if (recherche.lat) {
    params.lat = recherche.lat;
  }
  if (recherche.lng) {
    params.lng = recherche.lng;
  }
  if (recherche.box) {
    let firstlat = Math.max(recherche.box[0][1], recherche.box[0][0]);
    let secondlat = Math.max(recherche.box[1][1], recherche.box[1][0]);
    let firstlng = Math.min(recherche.box[0][1], recherche.box[0][0]);
    let secondlng = Math.min(recherche.box[1][1], recherche.box[1][0]);
    // _ne: {
    //   lat: Math.max(firstlat, secondlat),
    //   lng: Math.min(firstlng, secondlng),
    // },
    // _sw: {
    //   lat: Math.min(firstlat, secondlat),
    //   lng: Math.max(firstlng, secondlng),
    // },
    params.bounds = [
      Math.min(firstlat, secondlat),
      Math.min(firstlng, secondlng),
      Math.max(firstlat, secondlat),
      Math.max(firstlng, secondlng),
    ];
  }
  if (recherche.niveau == "provinces") {
    if (recherche.province) {
      params.province = norm(recherche.province);
    }
  }
  if (recherche.niveau == "communes") {
    if (recherche.commune) {
      params.commune = norm(recherche.commune);
    }
  }
  if (recherche.niveau == "region") {
    if (recherche.region) {
      params.region = norm(recherche.region);
    }
  }
  if (prixMax) {
    params.prixmax = prixMax;
  }
  params.type = checked_type;
  return new URLSearchParams(params).toString();
};

export default getUrlParams;
