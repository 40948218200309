import React from "react";
import PlacesAutocomplete from "../../../utils/PlacesAutocomplete/PlacesAutocomplete";
import style from "./style.module.css";

function index(props) {
  return (
    <div className={style.searchInputButtonContainer}>
      <div className={style.searchCon}>
        <div className={style.search}>
          <div className={style.searchInput}>
            <PlacesAutocomplete
              placeholder={props.placeholder}
              page="landing-page"
              hide_icon={true}
              showSearchIcon={true}
              lang={props.lang}
            />
          </div>
          <div className={style.btnCon}>
            <a
              href={"#"}
              onClick={(e) => {
                void 0;
                e.preventDefault();
              }}
            >
              {true ? (
                <button className={style.searchBtn}>{props.button}</button>
              ) : (
                <button className={style.searchBtn}>
                  <div className={style.btn__content}>
                    <i className="fas fa-circle-notch fa-spin"></i>
                  </div>
                </button>
              )}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
