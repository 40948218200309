import useTranslation from "next-translate/useTranslation";
import nookies from "nookies";
import React, { useEffect, useState } from "react";
import formatUrl from "utils/FormatUrl";
import getRPCQfromCoords from "utils/getRPCQfromCoords";
import PlacesAutocomplete from "../../../utils/PlacesAutocomplete/PlacesAutocomplete";
import style from "./searchBar.module.css";
import { norm } from "components/v2/Listings/ListingsRouterUrlHelper";
import getUrlParams from "utils/PlacesAutocomplete/getUrlParams";
import { BsJustify } from "react-icons/bs";

function index(props) {
  const { lang } = useTranslation("connexionInscription");
  const [check, setCheck] = useState(2);
  const [coordonnates, setcoordonnates] = useState({
    // actif: "estimer",
    address: null,
    lat: null,
    lng: null,
    activateGMAPS: false,
    recentSearches: [],
    displayRecentSuggestions: false,
    recentSuggestions: [],
    zoom: 5,
    speed: 2.5,
    handle_recherche: false,
    commune: null,
    province: null,
    region: null,
    box: null,
  });

  const [handleRecherche, sethandleRecherche] = useState(false);

  useEffect(() => {
    const cookies = nookies.get(undefined);
    if (cookies?.rechercheUser) {
      setCheck(2);
    }
  }, []);

  const setInCookies = (count) => {
    const nookiesData = count;
    nookies.set(undefined, "rechercheUser", nookiesData, {
      maxAge: 30 * 24 * 60 * 60,
      path: "/",
    });
  };

  const postHandleSelect = (recherche, transaction_type) => {
    setcoordonnates({
      ...coordonnates,
      lat: recherche.lat,
      lng: recherche.lng,
      zoom: recherche.zoom,
      speed: recherche.speed,
      address: recherche.adresse
        ? recherche.adresse
        : recherche.address
        ? recherche.address
        : "",
      commune: recherche.commune,
      province: recherche.province,
      region: recherche.region,
      box: recherche.box ? recherche.box : null,
    });

    if (check == 1) {
      handleRechercheLP({
        recherche,
      });
    } else if (check == 2) {
      handleRedirect(recherche, "Vente");
    } else {
      handleRedirect(recherche, "Location");
    }
  };

  const handleRechercheLP = (recherche, lang = "fr") => {
    sethandleRecherche(true);
    const u = getUrlParams(recherche.recherche, "Vente");
    window.location.href = `/${lang}/estimation/map?${u}`;
    sethandleRecherche(false);
    // window.open(`/${lang}/list.htm?${u}`);
  };

  const handleRedirect = (recherche, transaction_type, lang = "fr") => {
    sethandleRecherche(true);
    const u = getUrlParams(recherche, transaction_type);
    window.location.href = `/${lang}/list.htm?${u}`;
    sethandleRecherche(false);
    // window.open(`/${lang}/list.htm?${u}`);
  };

  return (
    <div className={style.container}>
      <div className={style.searchContainer}>
        {/* swap btn1 & btn2 */}
        <div className={style.btncontainer}>
          <button
            data-tracking="click"
            data-value=""
            className={check == 1 ? style.btnCheck : style.btn}
            onClick={() => {
              setInCookies(1);
              setCheck(1);
            }}
          >
            {props.button1}
          </button>
          <button
            className={check == 2 ? style.btnCheck : style.btn}
            onClick={() => {
              setInCookies(2);
              setCheck(2);
            }}
          >
            {props.button2}
          </button>
          <button
            className={check == 3 ? style.btnCheck : style.btn}
            onClick={() => {
              setInCookies(3);
              setCheck(3);
            }}
          >
            {props.button3}
          </button>
        </div>
        <div
          className={
            props.lang == "ar" ? style.searchConarabicV : style.searchCon
          }
        >
          <span className={style.subtitle}>
            {check == 1
              ? props.estimationSubTitle
              : check == 2
              ? props.venteSubTitle
              : props.locationSubTitle}
          </span>
          <div className={style.search}>
            <div className={style.searchInput}>
              <PlacesAutocomplete
                enableMajorZoom={true}
                allowMaps={check == 1}
                enableDefaultSearchList={true}
                placeholder={
                  check == 1 ? props.estimerPlaceholder : props.placeholder
                }
                postHandleSelect={postHandleSelect}
                page="landing-page"
                hide_icon={true}
                showSearchIcon={true}
                lang={lang}
                lpHome={props.lpHome}
              />
            </div>
            <div
              className={style.btnCon}
              data-tracking="click"
              data-value={
                check == 1 ? "estimation" : check == 2 ? "Vente" : "Location"
              }
            >
              <a
                href={formatUrl(
                  check == 1
                    ? `/estimation/map${
                        getUrlParams(coordonnates, null)
                          ? `?${getUrlParams(coordonnates, null)}`
                          : ""
                      }`
                    : check == 2
                    ? `/list.htm?${getUrlParams(coordonnates, "Vente")}`
                    : `/list.htm?${getUrlParams(coordonnates, "Location")}`,
                  lang
                )}
                rel="nofollow"
                onClick={(e) => {
                  void 0;
                  e.preventDefault();
                }}
              >
                {!handleRecherche ? (
                  <span
                    className={style.searchBtn}
                    onClick={() => {
                      sethandleRecherche(true);
                      if (check == 1) {
                        handleRechercheLP({
                          recherche: coordonnates,
                        });
                      } else if (check == 2) {
                        handleRedirect(coordonnates, "Vente", lang);
                      } else {
                        handleRedirect(coordonnates, "Location", lang);
                      }
                    }}
                  >
                    {check == 1 ? props.button : props.buttonVenteSearch}
                  </span>
                ) : (
                  <div className={style.searchBtn}>
                    <span className={style.btn__content}>
                      <i className="fas fa-circle-notch fa-spin"></i>
                    </span>
                  </div>
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
