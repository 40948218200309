import dynamic from "next/dynamic";
import React from "react";
import AnnoncesSearchBar from "SanityComponents/Annonces/AnnoncesSearchBar";
import SearchBarInputButton from "SanityComponents/GuideDuCredit/SearchBarInputButton";
import PROXY_CONFIG from "../../../Config/ProxyConfig";
import SearchBar from "../../../SanityComponents/LandingPage/SearchBar";
import styles from "./HeaderEstimation.module.css";
import Image from "next/image";
import api_call_v2 from "utils/API_CALL/api_call_v2";
import formatUrl from "utils/FormatUrl";

const LPSearchBar: any = dynamic(
  () =>
    import(
      "../../../components/LandingPage/Mobile/MobileSearchBar/LPSearchBar"
    ),
  { ssr: true }
);

function index(props) {
  const serializers: any = {
    h1: (props) => <h1 style={{ color: "red" }} {...props} />,
    li: ({ children }) => <li className="special-list-item">{children}</li>,
  };

  const customHandleRecherche = async (recherche) => {
    let data = {
      geometry_label: null,
      geometry_value: null,
    };
    await (async () => {
      if (recherche.niveau == "pas") {
        try {
          let res = await api_call_v2(undefined, "post", "cartographie/rgc", {
            lat: recherche.lat,
            lng: recherche.lng,
          });

          data = {
            geometry_label: res.data.commune
              ? "commune"
              : res.data.province
                ? "province"
                : res.data.region
                  ? "region"
                  : "region",
            geometry_value: res.data.commune
              ? res.data.commune
              : res.data.province
                ? res.data.province
                : res.data.region
                  ? res.data.region
                  : "",
          };
        } catch (e) {
          data = {
            geometry_label:
              recherche.niveau == "communes"
                ? "commune"
                : recherche.niveau == "pas"
                  ? "commune"
                  : recherche.niveau == "provinces"
                    ? "province"
                    : "region",
            geometry_value: recherche.commune
              ? recherche.commune
              : recherche.province
                ? recherche.province
                : recherche.region
                  ? recherche.region
                  : "",
          };
        }
      } else {
        data = {
          geometry_label:
            recherche.niveau == "communes"
              ? "commune"
              : recherche.niveau == "pas"
                ? "commune"
                : recherche.niveau == "provinces"
                  ? "province"
                  : "region",
          geometry_value: recherche.commune
            ? recherche.commune
            : recherche.province
              ? recherche.province
              : recherche.region
                ? recherche.region
                : "",
        };
      }
    })();

    window.location.href = `/${props.lang
      }/agence-immobiliere-maroc/recherche/?address=${recherche.adresse ? recherche.adresse : ""
      }&glabel=${data.geometry_label}&gvalue=${data?.geometry_value}`;
  };
  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.overlay_image}
          style={{ opacity: `${props.overlay ? props.overlay : 0}%` }}
        ></div>
        {/* <img
          className={styles.backgroundImage}
          src={props.backgroundUrl}
          alt={props.title}
        /> */}
        <Image
          unoptimized
          className={styles.backgroundImage}
          src={props.backgroundUrl}
          alt={props.title}
          fill
        />
        {/* <img
          width="100%"
          height="100%"
        /> */}

        <div className={styles.inner}>
          <div className={styles.vertical_center}>
            <div>
              {props.title &&
                (props.index > 1 ? (
                  <h2 className={styles.title}>{props.title}</h2>
                ) : (
                  <h1 className={styles.title}>{props.title}</h1>
                ))}
              {props.subtitle && (
                <h2
                  className={`${styles.subtitle} ${props.temoignages ? styles.subtitleTem : ""
                    }`}
                >
                  {props.subtitle}
                </h2>
              )}
              {props.secondeSubtitle && (
                <p className={styles.secondeSubtitle}>
                  {props.secondeSubtitle}
                </p>
              )}
            </div>
            {props.buttonText && (
              <div className={`${styles.divBtn} ${styles.mobile_}`}>
                <button className={styles.btn}>
                  <a
                    href={
                      props.button_link
                        ? props.button_link
                        : formatUrl(props.button_link, props.lang)
                    }
                    target="_blank"
                    rel={
                      props.button_link?.includes("list") ||
                        props.button_link?.includes("map") ||
                        props.button_link?.includes("?") ||
                        props.button_link?.includes("conditions") ||
                        props.button_link?.includes("contact")
                        ? "noindex, nofollow, noreferrer"
                        : "dofollow"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      let params = new URLSearchParams(
                        window.location.search
                      );
                      let size = Array.from(params).length;

                      if (params.get("version")) {
                        window.location.href = formatUrl(
                          `${props.button_link}${size ? `?${params.toString()}` : ""
                          }`,
                          props.lang
                        );
                      } else {
                        params.set(
                          "version",
                          process.env.NEXT_PUBLIC_VERSION_ESTIMATION
                        );

                        window.location.href = formatUrl(
                          `${props.button_link.split("?")[0]}${size ? `?${params.toString()}` : ""
                          }`,
                          props.lang
                        );
                      }
                    }}
                  >
                    {props.buttonText}
                  </a>
                </button>
              </div>
            )}
            <div className={styles.desktop_}>
              {props.searchBar != null && !props.trouvezUneAgence ? (
                <SearchBar
                  button1={
                    props.searchBar.buttonCheck1
                      ? props.searchBar.buttonCheck1[props.lang]
                      : ""
                  }
                  button2={
                    props.searchBar.buttonCheck2
                      ? props.searchBar.buttonCheck2[props.lang]
                      : ""
                  }
                  button3={
                    props.searchBar.buttonCheck3
                      ? props.searchBar.buttonCheck3[props.lang]
                      : ""
                  }
                  estimationSubTitle={
                    props.searchBar.estimationSubTitle
                      ? props.searchBar.estimationSubTitle[props.lang]
                      : ""
                  }
                  venteSubTitle={
                    props.searchBar.venteSubTitle
                      ? props.searchBar.venteSubTitle
                        ? props.searchBar.venteSubTitle[props.lang]
                        : ""
                      : ""
                  }
                  locationSubTitle={
                    props.searchBar.locationSubTitle
                      ? props.searchBar.locationSubTitle
                        ? props.searchBar.locationSubTitle[props.lang]
                        : ""
                      : ""
                  }
                  estimerPlaceholder={
                    props.searchBar
                      ? props.searchBar.Estimer_placeholder[props.lang]
                      : ""
                  }
                  placeholder={
                    props.searchBar.placeholder
                      ? props.searchBar.placeholder
                        ? props.searchBar.placeholder[props.lang]
                        : ""
                      : ""
                  }
                  button={
                    props.searchBar.titre_button
                      ? props.searchBar.titre_button
                        ? props.searchBar.titre_button[props.lang]
                        : ""
                      : ""
                  }
                  buttonVenteSearch={
                    props.searchBar.titre_button2
                      ? props.searchBar.titre_button2
                        ? props.searchBar.titre_button2[props.lang]
                        : ""
                      : ""
                  }
                  t={props.t}
                  lang={props.lang}
                />
              ) : null}
              {props.SearchBarInputButton != null && !props.trouvezUneAgence ? (
                <SearchBarInputButton
                  button={
                    props.SearchBarInputButton.titre_button
                      ? props.SearchBarInputButton.titre_button[props.lang]
                      : ""
                  }
                  placeholder={
                    props.SearchBarInputButton.placeholder
                      ? props.SearchBarInputButton.placeholder[props.lang]
                      : ""
                  }
                  t={props.t}
                  lang={props.lang}
                />
              ) : null}

              {props.trouvezUneAgence && props.searchBar ? (
                <div className={styles.desktop_}>
                  <LPSearchBar
                    display_top_section={false}
                    customHandleRecherche={customHandleRecherche}
                    title={
                      props.searchBar.estimationSubTitle
                        ? props.searchBar.estimationSubTitle[props.lang]
                        : ""
                    }
                    link={"agence-immobiliere-maroc/recherche"}
                    placeholder={
                      props.searchBar
                        ? props.searchBar.Estimer_placeholder[props.lang]
                        : ""
                    }
                    button_text={
                      props.searchBar.buttonCheck1
                        ? props.searchBar.buttonCheck1[props.lang]
                        : ""
                    }
                    trouvezAgence={true}
                    lang={props.lang}
                  />
                </div>
              ) : null}
              {props.annoncesSearchBar ? (
                <div className={styles.desktop_}>
                  <AnnoncesSearchBar
                    subtitle={
                      props.annoncesSearchBar.subtitle
                        ? props.annoncesSearchBar.subtitle[props.lang]
                        : ""
                    }
                    button={
                      props.annoncesSearchBar.button_text
                        ? props.annoncesSearchBar.button_text[props.lang]
                        : ""
                    }
                    buttonLink={props.annoncesSearchBar.buttonLink}
                    placeholder={
                      props.annoncesSearchBar.placeholder
                        ? props.annoncesSearchBar.placeholder[props.lang]
                        : ""
                    }
                    t={props.t}
                    lang={props.lang}
                  />
                </div>
              ) : null}
              {props.buttonText && (
                <div className={styles.divBtn}>
                  <button className={styles.btn}>
                    <a
                      href={formatUrl(props.button_link, props.lang)}
                      target="_blank"
                      rel={
                        props.button_link?.includes("list") ||
                          props.button_link?.includes("map") ||
                          props.button_link?.includes("?") ||
                          props.button_link?.includes("conditions") ||
                          props.button_link?.includes("contact")
                          ? "noindex, nofollow, noreferrer"
                          : "dofollow"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        let params = new URLSearchParams(
                          window.location.search
                        );
                        let size = Array.from(params).length;

                        if (params.get("version")) {
                          window.location.href = formatUrl(
                            `${props.button_link}${size ? `?${params.toString()}` : ""
                            }`,
                            props.lang
                          );
                        } else {
                          params.set(
                            "version",
                            process.env.NEXT_PUBLIC_VERSION_ESTIMATION
                          );

                          window.location.href = formatUrl(
                            `${props.button_link.split("?")[0]}${size ? `?${params.toString()}` : ""
                            }`,
                            props.lang
                          );
                        }
                      }}
                    >
                      {props.buttonText}
                    </a>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mobile_}>
        {props.searchBar && !props.trouvezUneAgence ? (
          <SearchBar
            button1={
              props.searchBar.buttonCheck1
                ? props.searchBar.buttonCheck1[props.lang]
                : ""
            }
            button2={
              props.searchBar.buttonCheck2
                ? props.searchBar.buttonCheck2[props.lang]
                : ""
            }
            button3={
              props.searchBar.buttonCheck3
                ? props.searchBar.buttonCheck3[props.lang]
                : ""
            }
            estimationSubTitle={props.searchBar.estimationSubTitle[props.lang]}
            venteSubTitle={
              props.searchBar.venteSubTitle
                ? props.searchBar.venteSubTitle[props.lang]
                : ""
            }
            locationSubTitle={
              props.searchBar.locationSubTitle
                ? props.searchBar.locationSubTitle[props.lang]
                : ""
            }
            placeholder={
              props.searchBar.placeholder
                ? props.searchBar.placeholder[props.lang]
                : ""
            }
            estimerPlaceholder={props.searchBar.Estimer_placeholder[props.lang]}
            button={
              props.searchBar.titre_button
                ? props.searchBar.titre_button[props.lang]
                : ""
            }
            buttonVenteSearch={
              props.searchBar.titre_button2
                ? props.searchBar.titre_button2[props.lang]
                : ""
            }
            t={props.t}
            lang={props.lang}
          />
        ) : null}
        {props.SearchBarInputButton != null && !props.trouvezUneAgence ? (
          <SearchBarInputButton
            button={props.SearchBarInputButton.titre_button[props.lang]}
            placeholder={props.SearchBarInputButton.placeholder[props.lang]}
            t={props.t}
            lang={props.lang}
          />
        ) : null}
      </div>
      {props.trouvezUneAgence && props.searchBar ? (
        <div className={styles.mobile_}>
          <LPSearchBar
            display_top_section={false}
            customHandleRecherche={customHandleRecherche}
            title={props.searchBar.estimationSubTitle[props.lang]}
            link={"agence-immobiliere-maroc/recherche"}
            placeholder={
              props.searchBar.placeholder
                ? props.searchBar.placeholder[props.lang]
                : ""
            }
            button_text={props.searchBar.buttonCheck1[props.lang]}
            trouvezAgence={true}
            lang={props.lang}
          />
        </div>
      ) : null}
      {props.annoncesSearchBar ? (
        <div className={styles.mobile_}>
          <AnnoncesSearchBar
            subtitle={props.annoncesSearchBar.subtitle[props.lang]}
            button={props.annoncesSearchBar.button_text[props.lang]}
            buttonLink={props.annoncesSearchBar.buttonLink}
            placeholder={props.annoncesSearchBar.placeholder[props.lang]}
            t={props.t}
            lang={props.lang}
          />
        </div>
      ) : null}
    </>
  );
}

export default index;
